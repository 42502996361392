<div class="lang-cont" [ngClass]="showLanguageOptions ? 'show' : 'hide'">
  <div (click)="toggle()">
    <img [src]="selectedLanguage.image">
    <span [translate]="'funnel.generic.language.select.' + selectedLanguage.code | translate"></span>
    <span class="lang-icon">
      <i class="fa fa-chevron-down"></i>
    </span>
  </div>
  <ul>
    <li *ngFor="let language of languages" [ngClass]="language">
      <span class="divider"></span>
      <div class="language" (click)="setLanguage(language)">
        <img [src]="language.image">
        <span [translate]="'funnel.generic.language.select.' + language.code | translate"></span>
      </div>
    </li>
  </ul>
</div>
