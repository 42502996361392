import { KeycloakOnLoad } from 'keycloak-js';
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export const environment = {
  production: false,
  api: 'https://snl.flusso.nl/svnl/api',
  keycloak: {
    url: 'https://login.snl.flusso.nl',
    realm: 'Svnl',
    clientId: 'funnel',
    initOptions: {
      onLoad: 'check-sso' as KeycloakOnLoad,
      silentCheckSsoRedirectUri: '',
      redirectUri: '',
    },
  },
  endOfYearWarning: {
    enabled: false,
    currentYear: 2023,
    nextYear: 2024,
    validYear: 2023,
  },
  buckaroo: {
    applepay: {
      displayName: 'Sportvisserij Nederland (Dev)',
      domainName: 'funnel.snl.flusso.nl',
      merchantIdentifier: JSON.stringify('4C26589D1AAC47B0A409344CBEF394C2'),
      label: 'Sportvisserij Nederland Webshop',
    },
  },
  portal: {
    login: 'http://localhost:8080/#!',
    account: JSON.stringify('http://localhost:8080/#!/profile'),
    coupling: JSON.stringify('http://localhost:8080/#!/coupling/cardnumber'),
  },
  analytics: {
    google: null,
    hotjar: null,
    adftrack: null,
    gtm: null,
  },
  showDebugFooter: true,
  cancelDisabled: true,
  maintenance: true,
};
