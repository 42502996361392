import { Injectable } from '@angular/core';
import { Language } from '@model/language';
import { TranslateService } from '@ngx-translate/core';
import { marker as extract } from '@shavenzov/ngx-translate-extract-marker';


@Injectable({
  providedIn: 'root',
})
export class LanguageService {

  languages: Language[] = [
    { code: 'NL', label: 'funnel.generic.country.NL', image: '/assets/images/flags/NL.png' },
    { code: 'DE', label: 'funnel.generic.country.DE', image: '/assets/images/flags/DE.png' },
    { code: 'EN', label: 'funnel.generic.country.GB', image: '/assets/images/flags/EN.png' },
    { code: 'FR', label: 'funnel.generic.country.FR', image: '/assets/images/flags/FR.png' },
    { code: 'PL', label: 'funnel.generic.country.PL', image: '/assets/images/flags/PL.png' }];

  private language = this.languages[0];

  constructor(private translateService: TranslateService) {
    extract('language.country.nl');
    extract('language.country.de');
    extract('language.country.en');
    extract('language.country.fr');
    extract('language.country.pl');

    this.translateService.addLangs(['nl, de, fr, en, pl']);
  }

  getCurrentLanguageCode(): string {
    return this.language.code.toLowerCase() ?? this.translateService.getBrowserLang();
  }

  getLanguage(): Language {
    this.language = this.languages.find(lang => localStorage.getItem('language') === lang.code.toLowerCase());

    return this.language;
  }

  getLanguages(): Language[] {
    return this.languages;
  }

  setLanguage(code: string): void {
    this.language = this.languages.find(lang => code === lang.code);
    this.translateService.use(code.toLowerCase()).subscribe();
    localStorage.setItem('language', code.toLowerCase());
  }
}
