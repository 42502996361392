import { Component, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'svnl-header-home',
  templateUrl: './header-home.component.html',
  styleUrls: ['./header-home.component.scss'],
})
export class HeaderHomeComponent implements OnInit {
  isLoggedIn = false;

  constructor(
    private readonly keycloakService: KeycloakService,
  ) {
  }

  ngOnInit(): void {
    this.keycloakService.isLoggedIn().then(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });
  }

  get currentYear(): number {
    return environment.endOfYearWarning.currentYear;
  }
}
