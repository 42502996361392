import { Component, OnInit } from '@angular/core';
import { Language } from '@model/language';
import { LanguageService } from '@service/language.service';

@Component({
  selector: 'svnl-language-dropdown',
  templateUrl: './language-dropdown.component.html',
  styleUrls: ['./language-dropdown.component.scss'],
})
export class LanguageDropdownComponent implements OnInit {
  languages = [];
  selectedLanguage;
  showLanguageOptions = false;

  constructor(
    private languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.languages = this.languageService.getLanguages();
    this.selectedLanguage = this.languageService.getLanguage();
  }

  setLanguage(language: Language): void {
    this.selectedLanguage = language;
    this.languageService.setLanguage(this.selectedLanguage.code);
    this.showLanguageOptions = false;
  }

  toggle(): void {
    this.showLanguageOptions = !this.showLanguageOptions;
  }
}
