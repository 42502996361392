/* eslint-disable @angular-eslint/component-selector */
import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  Data,
  NavigationEnd,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { Session } from '@model/session';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@service/analytics.service';
import { SessionService } from '@service/session.service';
import { filter, map } from 'rxjs';

import { environment } from '../environments/environment';

interface RouteData {
  data: Data;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  session: Session;
  wizardStep?: number;
  isHomePage: boolean;
  showDebugFooter: boolean;

  constructor(
    private readonly router: Router,
    private readonly sessionService: SessionService,
    private readonly activatedRoute: ActivatedRoute,
    private readonly translateService: TranslateService,
    private readonly analyticsService: AnalyticsService,
  ) {

    // Add reverse compatibility support for deeplinks to our old website, which used hashbang's for navigation.
    router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((item: NavigationStart) => {
      if (!!item.url && item.url.match(/^\/#!/)) {
        const newUrl = item.url.replace('/#!', '');
        this.router.navigateByUrl(newUrl);
      }
    });

    router.events.pipe(filter(event => event instanceof NavigationEnd), map((item: NavigationEnd) => {
      this.analyticsService.pageView({ event: 'page', label: item.url });
    }));
  }

  ngOnInit(): void {
    const defaultLang = localStorage.getItem('language') ?? this.translateService.getBrowserLang();
    localStorage.setItem('language', defaultLang);
    this.translateService.use(defaultLang);

    this.isHomePage = this.router.url.includes('home');
    this.showDebugFooter = environment.showDebugFooter;
    this.checkCurrentUrlForHome();
    this.updateSession();
    this.subscribeRouterEvents();
  }

  updateSession(): void {
    this.session = this.sessionService.getSession();
  }

  clearSession(): void {
    this.sessionService.clearSession();
  }

  private static getRecursiveRouteData(snapshot: ActivatedRouteSnapshot): RouteData {
    if (snapshot.children.length > 0) {
      // Get branches
      const childrenData = snapshot.children.reduce(({
        data: accData,
      }: RouteData, child) => {
        const { data: childData } = AppComponent.getRecursiveRouteData(child);

        return {
          data: { ...accData, ...childData },
        };
      }, { data: {} });

      return {
        data: { ...snapshot.data, ...childrenData.data },
      };
    }

    return {
      data: snapshot.data,
    };
  }

  private checkCurrentUrlForHome() {
    this.isHomePage = this.router.url.includes('home');
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.isHomePage = this.router.url.includes('home');
      }
    });
  }

  private subscribeRouterEvents() {
    this.router.events
      .pipe(
        filter((event: unknown): event is RouterEvent => event instanceof RouterEvent),
      )
      .subscribe((routerEvent: RouterEvent) => {
        switch (true) {
          case routerEvent instanceof NavigationEnd: {
            const routeData = AppComponent.getRecursiveRouteData(this.activatedRoute.snapshot);
            this.wizardStep = routeData.data['wizardStep'];
            break;
          }
          default: {
            break;
          }
        }
      });
  }
}
